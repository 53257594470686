// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

// application
import 'packs/application.js'

// js
import 'packs/users/javascripts/jquery-3.4.1.min'
import 'packs/users/javascripts/bootstrap.min'
import 'packs/users/javascripts/jquery-ui.min'
import 'packs/users/javascripts/common'
import 'packs/users/javascripts/custom'

// css
import 'packs/users/stylesheets/bootstrap.min'
import 'packs/users/stylesheets/jquery-ui.min'
import 'packs/users/stylesheets/jquery-ui.theme.min'
import 'packs/users/stylesheets/common'
import 'packs/users/stylesheets/custom'
