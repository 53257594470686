// JavaScript Document

$(function () {
	//js-flashMessage
    setTimeout(function(){
        $('.js-flashMessage').fadeOut();
    },5000);
	
	/* for top anchor */
	$('a[href^="#for_top"]').click(function(){
		console.log('click');
	var speed = 500;
	var href= $(this).attr("href");
	var target = $(href == "#" || href == "" ? 'html' : href);
	var position = target.offset().top;
	$("html, body").animate({scrollTop:position}, speed, "swing");
	return false;
	});

	/* navigation */
  $('.js-sidebar_toggle').on('click', function () {
	  //console.log('click');
    if ($('.js-sidebar').css('display') == 'none') {
      $('.js-sidebar').slideDown();
		$('.js-sidebar_toggle').addClass('isActive');
      //$('.main').hide();
    } else {
      $('.js-sidebar').slideUp();
		$('.js-sidebar_toggle').removeClass('isActive');
      //$('.main').show();
    }
  });
	
	// accordion
	$('.js-blockAccordion dt').on('click', function(){
		$(this).toggleClass('isActive').next('dd').slideToggle();
		return false;
	})
	
	//js-userSelecter
    
    wH = $(window).height();
	console.log(wH);
    $(window).resize(function() { //windowリサイズしたら高さを再取得
        wH = $(window).height();
        f_userSelecter_close();
    });
    $('.js-userSelecter').css('position','fixed');
    $('.js-userSelecter').css('top',wH);
    $('.js-userSelecter').css('width','100%');
    $('.js-userSelecter').css('opacity','0');
    $('.js-userSelecter .js-userSelecter_list').css('max-height',wH/2);
    cardH = $('.js-userSelecter').height();
    $('.js-userSelecter_trigger').on('click',function(){
            $('body').css('position','fixed');
            $('body').css('width','100%');
            $('body').css('height','100%');
            $('.js-userSelecter').css('opacity','1');
            $('.js-userSelecter').css('top',wH-cardH);
            $('.js-userSelecter').css('z-index','200');
            $('.js-userSelecter').css('transition','all 0.3s');
            $('body').append('<div class="js-userSelecter_overlay js-userSelecter_close" style="height:'+wH+'px; width:100%; background:rgba(0,0,0,0.8); position:fixed; top:0; z-index:100; transition:background 0.5s 0.1s"></div>');
            $('.js-userSelecter_overlay').css('transition','background-color 1s linear');
            return false;
    });
    
    $(document).on('click','.js-userSelecter_close',function(){
        f_userSelecter_close();
    });
    
    //userSelecter閉じる
    var f_userSelecter_close = function(){
            $('body').css('position','static');
            $('body').css('width','auto');
            $('body').css('height','auto');
            $('.js-userSelecter').css('opacity','0');
            $('.js-userSelecter').css('top',wH-cardH+305);
            $('.js-userSelecter').css('z-index','0');
            $('.js-userSelecter').css('transition','all 0.3s');
            $('div.js-userSelecter_overlay').remove();
            return false;
    }

	
/* キャンセル注意アラート
-------------------------------------- */
$('.js-cancelAlert').on('click',function(){
    if(confirm('本当にキャンセルしてもよろしいですか？')){
        return true;
    }else{
        return false;
    }
});
	
/* リンク範囲拡大
-------------------------------------- */
	$('.js-linkBlock').click(function(){
         if($(this).find("a").attr("target")=="_blank"){
             window.open($(this).find("a").attr("href"), '_blank');
         }else{
             window.location=$(this).find("a").attr("href");
         }
     return false;
     });

	
	

});
