$(function () {

  /* 二重投稿防止 */
  $('input:submit').on('click', function() {
    var this_class = $(this);
    if (this_class.hasClass('isDisabled')) {
      return false;
    }
    this_class.addClass('isDisabled');
    setTimeout(function() {
      this_class.removeClass('isDisabled');
    }, 3000);
  });

  /* 二重投稿防止 */
  $('button:submit').on('click', function() {
    var this_class = $(this);
    if (this_class.hasClass('isDisabled')) {
      return false;
    }
    this_class.addClass('isDisabled');
    setTimeout(function() {
      this_class.removeClass('isDisabled');
    }, 3000);
  });

  /* userSelecterの表示位置修正 */
  $('.js-userSelecter_trigger').on('click',function(){
    wH = $(window).height();
    cardH = $('.js-userSelecter').height();
    $('.js-userSelecter').css('top',wH-cardH);
    return false;
  });

  /*
   * パスワードフォーム
   */
  $(".js-type_password:input").focusin(function() {
    $(this).attr("type", "password");
  });

});
