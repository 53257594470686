// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require('jquery')

// yarn
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all'
import 'packs/application.scss'

window.$ = $;
